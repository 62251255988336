import React, { Component } from 'react'
import PageWrapper from '../components/PageWrapper/PageWrapper'
import SEO from '../components/seo'
import PageNotFound from "../components/Pages/PageNotFound/PageNotFound"

class Page404 extends Component {
  render() {
    return (
      <PageWrapper>
        <SEO title='Page Not Found'/>
        <PageNotFound/>
      </PageWrapper>
    )
  }
}

export default Page404
