import { Link } from "gatsby"
import * as React from "react"
import FooterBottom from "../../UI/FooterBottom/FooterBottom"
import Icon from "../../UI/Icon/Icon"

import "./PageNotFound.sass"

class PageNotFound extends React.Component {


  render() {

    return (
      <div className='page-404'>
        <div className='page-404__content max-width'>
          <Icon name='heading-something-wrong' className='heading' raw />
          <h2 className='page-404__title'>You hit a <span className='underlined'>404</span></h2>
          <div className='page-404__text'>Maybe the link was deleted or there was a typo in the link</div>
          <Link to='/' className='page-404__button  button button-filled'>Back to home</Link>
          <img src='../../../images/spaceman.png' alt='Illustration: spaceman' className='page-404__image'/>
        </div>
        <FooterBottom/>
      </div>
    )
  }
}

export default PageNotFound
